<template>
    <div>
        <div class="subtitle font-bold">Thông tin giấy tờ</div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Hạn phù hiệu tuyến</div>
            <div class="pl-2 font-bold">
                <a
                    href="#"
                    class="primary--text"
                    style="text-decoration: underline"
                    >{{
                        model.hanPhuHieuTuyen
                            ? moment(model.hanPhuHieuTuyen).format("DD/MM/yyyy")
                            : ""
                    }}</a
                >
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Hạn đăng kiểm</div>
            <div class="pl-2 font-bold">
                <a
                    href="#"
                    class="primary--text"
                    style="text-decoration: underline"
                    >{{
                        model.hanDangKiem
                            ? moment(model.hanDangKiem).format("DD/MM/yyyy")
                            : ""
                    }}</a
                >
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Hạn bảo hiểm</div>
            <div class="pl-2 font-bold">
                <a
                    href="#"
                    class="primary--text"
                    style="text-decoration: underline"
                    >{{
                        model.hanBaoHiem
                            ? moment(model.hanBaoHiem).format("DD/MM/yyyy")
                            : ""
                    }}</a
                >
            </div>
        </div>
    </div>
</template>
<script>
import LenhDienTu from "../../../../class/LenhDienTu";
export default {
    components: {},
    props: {
        model: {
            type: Object,
            default: () => new LenhDienTu().get(),
        },
    },
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>