<template>
    <ion-page>
        <ThanhTieuDeVue TieuDe="Báo cáo bán vé cho chuyến đi"
            ><template #after><div></div></template
        ></ThanhTieuDeVue>
        <ion-content :scroll-events="false">
            <div class="container-md">
                <ThongTinDangThucHienVue
                    :model="data.ChiTietLenh"
                    :key="`${componentKey}-2`"
                />
                <ThongTinGiayToVue
                    :model="data.ChiTietLenh"
                    :key="`${componentKey}-3`"
                    class="pt-2"
                />
                <ThongTinDNVTVue
                    :model="data.ChiTietLenh"
                    :key="`${componentKey}-4`"
                    class="pt-2"
                />
                <ThongTinCapLenhVue
                    :model="data.ChiTietLenh"
                    :key="`${componentKey}-5`"
                    class="pt-2"
                />
                <ThongTinChuyenDiVue
                    :model="data.ChiTietLenh"
                    :key="`${componentKey}-6`"
                    class="pt-2"
                />
                <ThongTinThucTeVue
                    :model="data.ChiTietLenh"
                    :key="`${componentKey}-7`"
                    class="pt-2"
                />
                <ThongTinKyLenhVue
                    :model="data.ChiTietLenh"
                    :key="`${componentKey}-8`"
                    class="pt-2"
                />
            </div>
            <div class="floating-button row justify-center align-center">
                <ion-button @click="presentActionSheet" fill="clear"
                    ><ion-icon :icon="menu" style="font-size: 24px"></ion-icon
                ></ion-button>
                <!-- :disabled="tempError ? true : false" -->
            </div>
            <!-- ====================================Popup Từ chối lệnh ========================================= -->
            <PopupVue
                height="40%"
                :dialog="dialogTuChoiLenh"
                title="Từ chối lệnh"
                buttonTextRight="Xác nhận"
                buttonTextLeft="Hủy"
                @close="closeTuChoiLenh"
            >
                <template #content>
                    <TuChoiLenhVue
                        :data="data.ChiTietLenh"
                        ref="TuChoiLenh"
                        :key="`${componentKey}-1`"
                    />
                </template>
            </PopupVue>
            <!-- ====================================Popup Muốn tiếp nhận lệnh ========================================= -->
            <PopupVue
                height="170px"
                :dialog="dialogTiepNhanLenh"
                title="Bạn có chắc muốn tiếp nhận lệnh điện tử 0001/21.00001?"
                buttonTextRight="Xác nhận"
                buttonTextLeft="Hủy"
                heightScrollView="0px"
                @close="closeTiepNhanLenh"
            >
            </PopupVue>
            <!-- ====================================Chức năng ========================================= -->
            <!-- <PopupVue
                height="170px"
                :dialog="dialogChucNang"
                title=""
                classPopup="clear-rounder clear-padding"
                :useButtonLeft="false"
                :useButtonRight="false"
                :closeOnOutsideClick="true"
                heightScrollView=""
                @close="dialogChucNang = false"
                ><template #content
                    ><ChucNangVue
                        :data="[
                            {
                                id: 0,
                                icon: 'check-checkmarkCircle',
                                text: 'Tiếp nhận lệnh',
                            },
                            {
                                id: 1,
                                icon: 'clipboard-text-off',
                                text: 'Từ chối lệnh',
                            },
                            {
                                id: 2,
                                icon: 'file-eye',
                                text: 'Xem bản thể hiện',
                            },
                        ]"
                        @onItemClick="onItemClickChucNang"
                /></template>
            </PopupVue> -->
            <!-- ====================================Bản thể hiện lệnh ========================================= -->
            <BanTheHienLenhVue
                :dialog="dialogBanTheHienLenh"
                :guidLenh="$route.query.guidLenh"
                @closes="
                    () => {
                        dialogBanTheHienLenh = false;
                    }
                " />
            <!-- ====================================Popup Thông báo ========================================= -->
            <PopupVue
                height="38%"
                :dialog="dialogThongBao"
                title=""
                :useButtonLeft="false"
                @close="
                    () => {
                        dialogThongBao = false;
                    }
                "
            >
                <template #content
                    ><ThongBaoVue
                        :message="ParamThongBao.message"
                        :state="ParamThongBao.state"
                        :title="ParamThongBao.title"
                    />
                </template> </PopupVue
        ></ion-content>
    </ion-page>
</template>
<script>
import ThanhTieuDeVue from "../../../src/components/_Common/ThanhTieuDe";
import ThongTinDangThucHienVue from "../../../src/components/QuanLyLenh/ItemDangThucHien/ThongTinDangThucHien";
import ThongTinGiayToVue from "../../../src/components/QuanLyLenh/ItemDangThucHien/ThongTinGiayTo";
import ThongTinDNVTVue from "../../../src/components/QuanLyLenh/ItemDangThucHien/ThongTinDNVT";
import ThongTinCapLenhVue from "../../../src/components/QuanLyLenh/ItemDangThucHien/ThongTinCapLenh";
import ThongTinChuyenDiVue from "../../../src/components/QuanLyLenh/ItemDangThucHien/ThongTinChuyenDi";
import ThongTinThucTeVue from "../../../src/components/QuanLyLenh/ItemDangThucHien/ThongTinThucTe";
import ThongTinKyLenhVue from "../../../src/components/QuanLyLenh/ItemDangThucHien/ThongTinKyLenh";
import TuChoiLenhVue from "../../../src/components/QuanLyLenh/TuChoiLenh";
import ChucNangVue from "../../../src/components/QuanLyLenh/ChucNang";
import BanTheHienLenhVue from "../../../src/components/QuanLyLenh/BanTheHienLenh";
import PopupVue from "../../../src/components/_Common/Popup";
import ThongBaoVue from "../../../src/components/_Common/ThongBao";
import LenhDienTu from "../../../class/LenhDienTu";
import LaiXe from "../../../class/LaiXe";
import {
    IonContent,
    IonPage,
    IonButton,
    actionSheetController,
} from "@ionic/vue";
import {
    checkmarkCircle,
    eye,
    documentLock,
    close,
    menu,
} from "ionicons/icons";
export default {
    setup() {
        checkmarkCircle, eye, documentLock, close, menu;
    },
    components: {
        ThanhTieuDeVue,
        ThongTinDangThucHienVue,
        ThongTinGiayToVue,
        ThongTinDNVTVue,
        ThongTinCapLenhVue,
        ThongTinChuyenDiVue,
        ThongTinThucTeVue,
        ThongTinKyLenhVue,
        PopupVue,
        TuChoiLenhVue,
        ChucNangVue,
        BanTheHienLenhVue,
        ThongBaoVue,
        IonContent,
        IonPage,
        IonButton,
    },
    props: {},
    data() {
        return {
            componentKey: 0,
            data: {
                ChiTietLenh: new LenhDienTu().get(),
            },
            dialogChucNang: false,
            dialogTuChoiLenh: false,
            dialogTiepNhanLenh: false,
            dialogBanTheHienLenh: false,
            dialogThongBao: false,
            tempError: false,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    methods: {
        async presentActionSheet() {
            let self = this;
            const actionSheet = await actionSheetController.create({
                header: "Chức năng",
                cssClass: "my-custom-class",
                buttons: [
                    {
                        text: "Tiếp nhận lệnh",
                        icon: checkmarkCircle,
                        handler: () => {
                            self.dialogTiepNhanLenh = true;
                        },
                    },
                    {
                        text: "Từ chối lệnh",
                        icon: documentLock,
                        handler: () => {
                            self.dialogTuChoiLenh = true;
                        },
                    },
                    {
                        text: "Xem bản thể hiện",
                        icon: eye,
                        handler: () => {
                            self.dialogBanTheHienLenh = true;
                        },
                    },
                    {
                        text: "Hủy",
                        role: "cancel",
                        icon: close,
                    },
                ],
            });
            await actionSheet.present();
            const { role } = await actionSheet.onDidDismiss();
        },
        async getData() {
            try {
                let lenh = new LenhDienTu();
                lenh.guidLenh = this.$route.query.guidLenh;
                let rs = await lenh.layChiTietLenh();
                if (!rs) {
                    throw new Error();
                }
                if (!rs.Data.status) {
                    this.tempError = true;
                    return this.thongBao(
                        "Error",
                        "Lỗi kết nối đến máy chủ",
                        rs.Data.message
                    );
                }
                this.data.ChiTietLenh = rs.Data.data;
                this.componentKey++;
            } catch (error) {
                console.log(
                    "🚀 ~ file: chi-tiet.vue ~ line 163 ~ getData ~ error",
                    error
                );
            }
        },
        // onItemClickChucNang(e) {
        //     switch (e.id) {
        //         case 0:
        //             this.dialogTiepNhanLenh = true;
        //             break;
        //         case 1:
        //             this.dialogTuChoiLenh = true;
        //             break;
        //         case 2:
        //             this.dialogBanTheHienLenh = true;
        //             break;

        //         default:
        //             break;
        //     }
        //     this.dialogChucNang = false;
        // },
        async closeTiepNhanLenh(param) {
            if (!param) {
                this.dialogTiepNhanLenh = false;
                return;
            }
            try {
                let rs = await new LaiXe().tiepNhanLenh(
                    this.$route.query.guidLenh
                );
                this.dialogTiepNhanLenh = false;
                if (!rs) {
                    throw this.thongBaoLoi();
                }
                if (!rs.Data.status) {
                    return this.thongBaoLoi(rs.Data.message);
                }
                this.$router.push({
                    path: "/thanh-cong",
                    query: {
                        title: "TIẾP NHẬN LỆNH THÀNH CÔNG",
                        message: "",
                    },
                });
            } catch (error) {
                this.thongBaoLoi();
                console.log(
                    "🚀 ~ file: chi-tiet.vue ~ line 178 ~ closeTiepNhanLenh ~ error",
                    error
                );
            }
        },
        async closeTuChoiLenh(param) {
            if (!param) {
                this.dialogTuChoiLenh = false;
                return;
            }
            try {
                let valid =
                    this.$refs.TuChoiLenh.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return;
                }
                let laiXe = new LaiXe();
                laiXe.lyDo = this.$refs.TuChoiLenh.model.LyDo;
                let rs = await laiXe.tuChoiLenh(this.$route.query.guidLenh);
                this.dialogTuChoiLenh = false;
                if (!rs) {
                    throw this.thongBaoLoi();
                }
                if (!rs.Data.status) {
                    return this.thongBaoLoi(rs.Data.message);
                }
                this.$router.push({
                    path: "/thanh-cong",
                    query: {
                        title: "Xác nhận từ chối lệnh thành công!",
                        message: "",
                    },
                });
            } catch (error) {
                console.log(
                    "🚀 ~ file: chi-tiet.vue ~ line 195 ~ closeTuChoiLenh ~ error",
                    error
                );
            }
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
        thongBaoLoi(message, log) {
            this.thongBao(
                "Error",
                message || "Đã xảy ra lỗi. Vui lòng thử lại"
            );
            console.log(
                "%c log ",
                "color: white; background-color: #95B46A",
                "=========>",
                log
            );
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
.floating-button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: $box_shadow;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 3;
    background-color: white;
}
</style>