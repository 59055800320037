<template>
    <div>
        <DxList
            :data-source="data"
            height="calc(100% - 125px)"
            page-load-mode="scrollBottom"
            key-expr="id"
            :hoverStateEnabled="false"
            :focusStateEnabled="false"
            :activeStateEnabled="true"
            no-data-text="Không có dữ liệu!"
            class="list-style pt-2"
            :onItemClick="onItemClick"
        >
            <!-- selection-mode="multiple" -->
            <template #item="{ data: item }">
                <div>
                    <div class="row align-center">
                        <div class="xs2">
                            <i :class="`mdi mdi-${item.icon}`"></i>
                        </div>
                        <div class="xs10 font-16">
                            {{ item.text }}
                        </div>
                    </div>
                </div>
            </template></DxList
        >
    </div>
</template>
<script>
import { DxList } from 'devextreme-vue';
export default {
    layout: "application",
    components: {DxList},
    props: {
        data: { type: Array, default: () => [] },
    },
    data() {
        return {};
    },
    methods: {
        onItemClick(e) {
            if (!e.itemData) {
                throw new Error("Không có dữ liệu trong e.itemData");
            }
            this.$emit("onItemClick",e.itemData)
        },
    },
    created() {},
    mounted() {},
};
</script>
<style scoped>
>>> .dx-template-wrapper.dx-item-content.dx-list-item-content {
    padding: 4px 16px 4px !important;
}
</style>
<style lang='scss' scoped>
</style>