<template>
    <div>
        <div class="subtitle font-bold">Thông tin ký lệnh</div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Thủ trưởng đơn vị</div>
            <div class="pl-2 font-bold">
                {{ model.thuTruongDonViDaKyLenh ? "Đã ký" : "Chưa ký" }}
            </div>
        </div>
        <div
            class="row pb-2 justify-space-between align-center"
            v-for="(item, index) in model.danhSachLaiXe"
            :key="item.guidLaiXe"
        >
            <div class="xs5">Lái xe {{ index + 1 }}</div>
            <div class="pl-2 font-bold">
                {{ `${item.tenLaiXe} - ${item.hangBangLai}` }}
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Bến đi</div>
            <div class="pl-2 font-bold">
                {{ model.benDiDaKyLenh ? "Đã ký" : "Chưa ký" }}
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Bến đến</div>
            <div class="pl-2 font-bold">
                {{ model.benDenDaKyLenh ? "Đã ký" : "Chưa ký" }}
            </div>
        </div>
    </div>
</template>
<script>
import LenhDienTu from "../../../../class/LenhDienTu";
export default {
    components: {},
    props: {
        model: {
            type: Object,
            default: ()=>new LenhDienTu().get(),
        },
    },
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>