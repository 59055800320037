<template>
    <div>
        <div class="subtitle font-bold">Thông tin chuyến đi</div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Số ghế | Số giường</div>
            <div class="pl-2 font-bold">
                {{ model.soGhe }} | {{ model.soGiuong }}
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Hình thức chạy</div>
            <div class="pl-2 font-bold">
                {{ model.hinhThucChay }}
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Hành trình tuyến</div>
            <div class="pl-2 font-bold">
                {{ model.hanhTrinhChay }}
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Nhân viên phục vụ xe</div>
            <div class="pl-2 font-bold">
                {{ model.nhanVienPhucVuTrenXe }}
            </div>
        </div>
    </div>
</template>
<script>
import LenhDienTu from "../../../../class/LenhDienTu";
export default {
    components: {},
    props: {
        model: {
            type: Object,
            default: ()=>new LenhDienTu().get(),
        },
    },
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>