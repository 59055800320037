<template>
    <div>
        <div class="subtitle font-bold">Thông tin thực tế</div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Giờ xuất bến</div>
            <div class="pl-2 font-bold">
                {{ moment(model.gioXuatBen).format("HH:mm") }}
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Số khách xuất bến</div>
            <div class="pl-2 font-bold">
                {{ model.soKhachXuatBen }}
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Giờ đến bến</div>
            <div class="pl-2 font-bold">
                {{ moment(model.gioDenBen).format("HH:mm") }}
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs5">Số khách đến bến</div>
            <div class="pl-2 font-bold">
                {{ model.soKhachDenBen }}
            </div>
        </div>
    </div>
</template>
<script>
import LenhDienTu from "../../../../class/LenhDienTu";
export default {
    components: {},
    props: {
        model: {
            type: Object,
            default: ()=>new LenhDienTu().get(),
        },
    },
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>