<template>
    <div>
        <div class="column align-center pb-3">
            <QrCodeVue class="boundary pa-2" :data="model.qrCode"/>
            <div class="font-italic font-bold pt-1">
                {{ model.maLenh }} ֎ {{ model.bienKiemSoat }}
            </div>
            <div class="font-italic font-bold pt-1">
                {{
                    model.hieuLucTuNgay
                        ? moment(model.hieuLucTuNgay).format("DD/MM/yyyy")
                        : ""
                }}
                -
                {{
                    model.hieuLucDenNgay
                        ? moment(model.hieuLucDenNgay).format("DD/MM/yyyy")
                        : ""
                }}
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs3">Trạng thái</div>
            <div
                class="pl-2 font-bold"
                :style="`color:${model.maMauTrangThai}`"
            >
                {{ model.trangThai }}
            </div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs3">Mã tuyến</div>
            <div class="pl-2 font-bold">{{ model.maTuyen }}</div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs3">Bến đi</div>
            <div class="pl-2 font-bold">{{ model.benDi }}</div>
        </div>
        <div class="row pb-2 justify-space-between align-center">
            <div class="xs3">Bến đến</div>
            <div class="pl-2 font-bold">{{ model.benDen }}</div>
        </div>
        <div
            class="row pb-2 justify-space-between align-center"
            v-for="(item, index) in model.danhSachLaiXe"
            :key="item.guidLaiXe"
        >
            <div class="xs3">Lái xe {{ index + 1 }}</div>
            <div class="pl-2 font-bold">
                <a
                    href="#"
                    class="primary--text"
                    style="text-decoration: underline"
                    >{{ `${item.tenLaiXe} - ${item.hangBangLai}` }}</a
                >
            </div>
        </div>
    </div>
</template>
<script>
import QrCodeVue from "../../QrCode";
import LenhDienTu from "../../../../class/LenhDienTu";
export default {
    components: { QrCodeVue },
    props: {
        model: {
            type: Object,
            default: () => new LenhDienTu().get(),
        },
    },
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>